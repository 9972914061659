<template>
  <div class="contain">

    <!--比赛封面-->
    <div class="contain-banner" v-if="eventInfo.endShow===2">
      <img :src="eventInfo.eventCover" alt="">
    </div>

    <!--排名信息-->
    <div class="contain-swiper" v-if="eventInfo.endShow===1">
      <div class="swiper-header">选手排名</div>
      <div class="swiper-center">
        <div class="content-student" id="scrollAreaTab">
        <el-table
            :data="rankingList"
            style="width: 100%;background-color: transparent">
          <el-table-column
              prop="date"
              label="排名"
              align="center"
          >
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="name"
              label="参赛选手"
          >
          </el-table-column>
          <el-table-column
              align="center"
              prop="finalScore"
              label="最终得分">
          </el-table-column>
        </el-table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {findById, getRanking} from "../../api/event";

export default {
  name: "showOver",
  data() {
    return {
      eventInfo: {},
      roleFlag: null,
      rankingList: []
    }
  },
  sockets: {
    // 连接成功
    onopen() {
      console.log("连接websocket成功");
    },
    // 接收消息
    onmessage(e) {
      this.getEventInfo(this.eventInfo.id)
    },
    // 连接报错
    onerror() {
      console.log("连接websocket出错");
    },
    // 关闭连接
    onclose() {
      console.log("websocket关闭");
    },
  },
  mounted() {
    //1管理 2评委 3主持 4大屏端
    this.roleFlag = sessionStorage.getItem('roleFlag');
  },
  created() {
    this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
    this.getEventInfo(this.eventInfo.id)
  },
  methods: {
    //查询比赛信息
    async getEventInfo(id) {
      const {data} = await findById(id);
      if (data.code === 200) {
        this.eventInfo = data.data
        if (this.eventInfo.endShow === 1) {
          //选手排名信息
          getRanking(this.eventInfo.id).then(res => {
            if (res.data.code === 200) {
              this.rankingList = res.data.data
              console.log('rankingList',this.rankingList)
            }
          })
          let scrollArea
          const time = setTimeout(() => {
            scrollArea = document.getElementById("scrollAreaTab");
            setInterval(function () {
              // 每次滚动的距离，可以根据需求进行调整
              scrollArea.scrollTop += 3;
              const scrollTop = scrollArea.scrollTop; // 滚动条顶部到可见区域顶部的距离
              const offsetHeight = scrollArea.offsetHeight; // 可见区域的高度
              const scrollHeight = scrollArea.scrollHeight; // 盒子总高度
              if (scrollTop + offsetHeight >= scrollHeight) {
                  setTimeout(()=>{
                    scrollArea.scrollTop = 0;
                    clearTimeout(time);
                  },500)
              }
            }, 100);
          }, 500)
        }
        if (this.roleFlag === '3') {
          if (this.eventInfo.startStatus === 1 && this.$route.path !== '/hostPage') {
            this.$router.push('/hostPage')
          }
          if (this.eventInfo.startStatus === 2 && this.$route.path !== '/drawControl') {
            this.$router.push('/drawControl')
          }
          if (this.eventInfo.startStatus === 3 && this.$route.path !== '/eventControl') {
            this.$router.push('/eventControl')
          }
          if (this.eventInfo.startStatus === 4 && this.$route.path !== '/eventOver') {
            this.$router.push('/eventOver')
          }
        }
        if (this.roleFlag === '4') {
          if (this.eventInfo.startStatus === 1 && this.$route.path !== '/beforeShow') {
            this.$router.push('/beforeShow')
          }
          if (this.eventInfo.startStatus === 2 && this.$route.path !== '/drawSort') {
            this.$router.push('/drawSort')
          }
          if (this.eventInfo.startStatus === 3 && this.$route.path !== '/eventShow') {
            this.$router.push('/eventShow')
          }
          if (this.eventInfo.startStatus === 4 && this.$route.path !== '/showOver') {
            this.$router.push('/showOver')
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.contain {

  .contain-banner {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: var( --theme_bg-color);
    img {
      width: 100%;
      height: 100%;
    }
  }

  width: 100%;
  height: 100%;
  background-color: #bf353b;

  .contain-left {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 50px;
    background: url("../../assets/images/left.png") no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    cursor: pointer;
  }

  .contain-right {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 50px;
    background: url("../../assets/images/right.png") no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    cursor: pointer;

  }

  .contain-swiper {
    width: 100%;
    height: 100%;
    background-image: val(--theme_bg-img);
    background-repeat: no-repeat  no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .swiper-header {
      width: 40%;
      height: 80px;
      box-sizing: border-box;
      margin-bottom: 30px;
      background: url("../../assets/images/new1.png") no-repeat center;
      background-size: contain;
      text-align: center;
      line-height: 80px;
      color: var(--theme_font-color);
      font-size: 45px;
      font-weight: bold;
    }

    .swiper-center {
      width: 80%;
      height: calc(100% - 200px);
      /*background-color: red;*/
      background: url("../../assets/images/new2.png") no-repeat;
      background-size: 100% 100%;
      padding: 3%;
      overflow: auto;
      /deep/.el-table .cell {
        line-height: normal;
      }
      /deep/.el-table th.el-table__cell>.cell{
        font-size: 30px;
      }
      /deep/.el-table td.el-table__cell>.cell{
        font-size: 30px;
      }

      /deep/ .el-table th.el-table__cell {
        background-color: transparent;
      }

      /deep/ .el-table tr {
        background-color: transparent;
        color: var(--theme_font-color);
      }

      /deep/ .el-table .warning-row td {
        background: #4D939F !important;
        color: var(--theme_font-color);
      }

      /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {

        background-color: transparent !important;

      }

      /deep/ .el-table .fixedRow td {
        background: #4D939F !important;
        color: var(--theme_font-color);
        position: sticky;
        bottom: 0;
        width: 100%;
      }

      .center-title {
        width: 100%;
        color: var(--theme_font-color);
        height: 100px;
        font-size: 30px;
        line-height: 100px;
        font-family: "楷体";
        font-weight: bold;
      }

      .content-student {
        width: 100%;
        height: calc(100% - 100px);
        overflow: auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-wrap: wrap;

        .student-box {
          width: 200px;
          height: 200px;
          margin: 0 0 40px 0;
          box-sizing: border-box;
          display: flex;

          img {
            width: 70%;
            height: 70%;
            border-radius: 50%;
          }

          .text-box {
            width: 30%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            font-size: 24px;
            color: var(--theme_font-color);
            font-family: "楷体";
          }
        }
      }

      .center-body {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;

        justify-content: space-around;

        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
        }

        .body-text {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          color: var(--theme_font-color);
          font-size: 22px;
          font-family: "楷体";
          font-weight: bold;

          span {
            margin-bottom: 20px;
          }
        }
      }

      .result-box {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .result-line {
          color: var(--theme_font-color);
          width: 40%;
          height: 50px;
          border-bottom: 3px solid;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 2% 10%;
          box-sizing: border-box;
          font-size: 24px;
          border-image: linear-gradient(to right, #8c7b7c, #fff, #8c7b7c) 1;;
        }
      }
    }

    .contain-left {
      position: absolute;
      left: 5%;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 50px;
      background: url("../../assets/images/left.png") no-repeat;
      background-size: 100% 100%;
      background-attachment: fixed;
      cursor: pointer;
    }

    .contain-right {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 50px;
      background: url("../../assets/images/right.png") no-repeat;
      background-size: 100% 100%;
      background-attachment: fixed;
      cursor: pointer;

    }

    .swiper-box {
      width: 80%;
      height: 60%;
      /*padding: 50px 150px;*/
      box-sizing: border-box;
      background: url("../../assets/images/borderBanner.png") no-repeat;
      background-size: 100% 100%;
      background-attachment: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .box-player {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        .player-box {
          width: 20%;
          margin: 20px 0;
          height: 100px;
        }
      }

      .box-title {
        width: 100%;
        color: var(--theme_font-color);
        font-size: 18px;
        text-align: center;
      }

      .box-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--theme_font-color);
      }
    }
  }
}

</style>
